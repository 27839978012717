import { type MouseEvent } from 'react';
import { type IntlShape } from 'react-intl';

import {
    GA4EventName,
    GA4FeatureCategory,
    type GA4ItemListName,
    trackEecProductClick,
    trackEecProductImpression,
} from '@jsmdg/tracking';
import { type Product } from '../../shared/types/searchResponse';
import { enhanceUrlWithSearchParams } from '../helper/enhanceUrlWithSearchParams';
import { useImpressionTracking } from './useImpressionTracking';
import { useProductTileData } from './useProductTileData';

type UseOnProductTileClickAndImpressionReturnType = {
    setImpressionTrackedElement: (element: HTMLAnchorElement | HTMLDivElement | null) => void;
    onProductTileClick: (event?: MouseEvent<HTMLButtonElement>) => Promise<void>;
};

const useOnProductTileClickAndImpression = (
    product: Product,
    position: number,
    intl: IntlShape,
    listInfo: {
        indexName?: string;
        queryId?: string;
        trackingListName?: GA4ItemListName;
        hasLocationFilter?: boolean;
    },
): UseOnProductTileClickAndImpressionReturnType => {
    const { indexName, queryId, trackingListName, hasLocationFilter } = listInfo;

    const { url, productTrackingData } = useProductTileData(product, intl, {
        indexName,
        queryId,
        trackingListName,
        hasLocationFilter,
    });

    const productTrackingPayload = {
        product: productTrackingData,
        position,
        list: trackingListName,
        featureCategory: GA4FeatureCategory.ProductList,
        itemListName: trackingListName,
        isLocationSelected: hasLocationFilter,
    };

    const setImpressionTrackedElement = useImpressionTracking(() =>
        trackEecProductImpression({
            ...productTrackingPayload,
            eventName: GA4EventName.ViewItemList,
        }),
    );

    const onProductTileClick = async (event?: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event?.preventDefault();
        window.location.href = enhanceUrlWithSearchParams(url);
        await window.yieldToMainThread();

        trackEecProductClick(productTrackingPayload);
    };

    return { setImpressionTrackedElement, onProductTileClick };
};

export { useOnProductTileClickAndImpression };
